// extracted by mini-css-extract-plugin
export var ArtistDescription = "JammieHolmes-module--ArtistDescription--g7bq4";
export var ArtistInfos = "JammieHolmes-module--ArtistInfos--2QTcf";
export var ButtonWrapper = "JammieHolmes-module--ButtonWrapper --SKLvk";
export var CardWrapper = "JammieHolmes-module--CardWrapper--JvVbO";
export var CarrouselWrapper2 = "JammieHolmes-module--CarrouselWrapper2--TQq+5";
export var Citations = "JammieHolmes-module--Citations--VkjnR";
export var DescriptionWrapper = "JammieHolmes-module--DescriptionWrapper--YVPVd";
export var ImageWrapper = "JammieHolmes-module--ImageWrapper--3zDRL";
export var LinkWrapper = "JammieHolmes-module--LinkWrapper--zf4k8";
export var MobileProtrait = "JammieHolmes-module--MobileProtrait--MKBHV";
export var More = "JammieHolmes-module--More--IJNiD";
export var MoreButton = "JammieHolmes-module--MoreButton--c6w+8";
export var NameWrapper = "JammieHolmes-module--NameWrapper--N2LFZ";
export var PdpWrapper = "JammieHolmes-module--PdpWrapper--LxDBt";
export var PhotosWrapper = "JammieHolmes-module--PhotosWrapper--umXFm";
export var ProfilWrapper = "JammieHolmes-module--ProfilWrapper--TxSyt";
export var TitleWrapper = "JammieHolmes-module--TitleWrapper--fGFae";
export var Wrapper = "JammieHolmes-module--Wrapper--ivHqE";