
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./JammieHolmes.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import JammieHolmesPhoto1 from "../../../../res/Photos site/Jammie Holmes/GG.jpg"
import JammieHolmesPhoto2 from "../../../../res/Photos site/Jammie Holmes/profil.jpg"
import JammieHolmesPhoto3 from "../../../../res/Photos site/Jammie Holmes/Jammie Holmes by Emery Davis .jpg"
import JammieHolmesPhoto4 from "../../../../res/Photos site/Jammie Holmes/1.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/holmes/portrait.jpg'
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "JammieHolmes",
  name: "JammieHolmes",
  description:"Né en 1984 en Louisiane aux États-Unis, Jammie Holmes est un artiste autodidacte dont l'oeuvre figurative expressive traite de la vie des afro-américains dans le « Deep South ». Marqué par le passé esclavagiste de sa région natale et de sa ville de Thibodaux en Louisiane, Jammie Holmes traite de sa mythologie en incorporant cette mémoire collective sur la toile. Artiste profondément engagé, il expose ses oeuvres à Los Angeles, Dallas, Detroit, New York mais également en Europe et à Rome plus précisément. En mai 2020, Holmes a attiré une attention particulière lorsqu'il a commandé des avions pour survoler les grandes villes américaines avec des bannières ornées des derniers mots de George Floyd. Les peintures qui en résultent chevauchent une représentation réaliste et un mode symbolique épuré. Jammie Holmes a à son actif plusieurs solo et group show aux États-Unis et aussi en Israël. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de JammieHolmes.",
  photos: [
    { src: JammieHolmesPhoto1, name: "JammieHolmes" },
    { src: JammieHolmesPhoto3, name: "JammieHolmes" },
    { src: JammieHolmesPhoto4, name: "JammieHolmes" },
  ],
  citations: []
};

const JammieHolmes = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>JAMMIE HOLMES <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1984</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Present Generations : Creating the Scantland collection of the Columbus Museum of Art, Ohio, USA, June 25th - May 22th.</p>
          <p>- Jammie Holmes: What Happened to the Soul Food?, Gana Art Center, Seoul, January 27 – February 27th.</p>
          <p>- What happened to the Soul Food?, Gana Art Center Seoul, South Korea, January 27th - February 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Pieces of a Man, Library Street Collective, Detroit, USA, May 22nd - June 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Everything Hurts, Dallas Contemporary, Dallas, USA, Mai 30th - Juin 16th.</p>
          <p>- Anatomy : Jammie Holmes, Library Street Collective, Detroit, USA, Août 27th - Octobre 8th.</p>
          <br />
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p  style={{fontWeight: "bold"}}>2022</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Present Generations : Creating the Scantland Collection of the Columbus of Art, Columbus Museum of Art, Columbus, USA, Juin 24th, 2021 - Mai 22nd, 2022.</p>
          <p>- Shattered Glass, Deitch Projects, Los Angeles, USA, Mars 20th - Mai 22nd.</p>
          <p>- In Situ, Marianne Boesky, New York, USA, Janvier 7th - Février 6th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- High Voltage, Curated by Suzanne Landau, Nassima-Landau Projects, Tel Aviv, Israel, Novembre 24th, 2020 - Janvier 16th, 2021.</p>
          <p>- To Be Determined, Curated by Vivian Crockett, Dallas Museum of Art, Dallas, USA, Septembre 27th - Decembre 27th.</p>
          <p>- We Used to Gather, Library Street Collective, New York, USA, Juillet 18th - Septembre 18th.</p>
          <p>- Dallas Art Fair Online, LMAK Gallery, Dallas, USA, Avril 14th - 23rd. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Eye To Eye, Band Of Vices, Los Angeles, USA, Septembre 12th - Octobre 10th.</p>
          <p id="end">- 23rd Annual No Dead Artists : International Juried Exhibition Of Contemporary Art, Jonathan Ferrara Gallery, New Orleans, USA, Septembre 4th - 28th.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default JammieHolmes;